/**
 * @setTitle 设置浏览器title
 */
const setTitle = (title) => {
    document.title = title;
    let u = navigator.userAgent,
        isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    if(isiOS) {
       console.log("ios"); 
    }
}

export {
    setTitle,
}
