import React, { Fragment } from "react";
import { 
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
// import Home from "./pages/home";
import Official from "./pages/official"; // PAR-COM LENDING INVESTORS CORPORATION
function App() {
  return (
    <Fragment>
      <Router>
          <Routes>
            <Route exact path="/" element={<Official />} />
            <Route path="/ocho" element={<Official />} />
            {/* 没有匹配的路由自动跳转默认的路由 */}
          </Routes>
          <Navigate to="/" replace={true} />
      </Router>
    </Fragment>
  );
}

export default App;
